import React from "react";
import BizowlLogo from "assets/img/layout/logo.png";

const LeftBoxContent = () => {
  return (
    <div>
      <img src={BizowlLogo} alt="logo" width="150px" />
    </div>
  );
};

export default LeftBoxContent;
