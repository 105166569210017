export const industryOption = [
  { value: 'Banking & Finance', label: 'Banking & Finance' },
  { value: 'Logistics & Transportation', label: 'Logistics & Transportation' },
  { value: 'Healthcare & Medical', label: 'Healthcare & Medical' },
  { value: 'Education & Learning', label: 'Education & Learning' },
  { value: 'Fashion & Lifestyle', label: 'Fashion & Lifestyle' },
  { value: 'Food and Beverages', label: 'Food and Beverages' },
  { value: 'Gaming', label: 'Gaming' },
  { value: 'Tourism', label: 'Tourism' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'E-commerce', label: 'E-commerce' },
  { value: 'Other', label: 'Other' },
];

export const serviceOptions = [
  { value: 'Fundamental Services', label: 'Fundamental Services' },
  { value: 'Web Development', label: 'Web Development' },
  { value: 'Digital Marketing', label: 'Digital Marketing' },
  { value: 'Design Services', label: 'Design Services' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Advertising', label: 'Advertising' }
]
export const budgetOption = [
  { value: '0-5 K', label: '0-5 K' },
  { value: '5-25 K', label: '5-25 K' },
  { value: '25-50 K', label: '25-50 K' },
  { value: '50-100 K', label: '50-100 K' },
  { value: '1- 5 L', label: '1- 5 L' },
  { value: '5 L+', label: '5 L+' },
];

export const subCategoryServicesData = [
  {
      "Fundamental Services": [
          { value: 'Market Research', label: 'Market Research' },
          { value: 'Idea Validation', label: 'Idea Validation' },
          { value: 'Business Model Development', label: 'Business Model Development' },
          { value: 'Target Audience Analysis', label: 'Target Audience Analysis' },
          { value: 'Market Entry Strategy', label: 'Market Entry Strategy' },
          { value: 'Financial Planning', label: 'Financial Planning' },
          { value: 'Startup Consulting', label: 'Startup Consulting' },
          { value: 'Business Canvas Model', label: 'Business Canvas Model' },
          { value: 'Market Size Analysis', label: 'Market Size Analysis' },
          { value: 'Competitor Analysis', label: 'Competitor Analysis' },
          { value: 'Other', label: 'Other' }
      ]
  },
  {
      "Web Development": [
          { value: 'App Development', label: 'App Development' },
          { value: 'Blog Website', label: 'Blog Website' },
          { value: 'Custom Site', label: 'Custom Site' },
          { value: 'E-Com Development', label: 'E-Com Development' },
          { value: 'Shopify Development', label: 'Shopify Development' },
          { value: 'Simple Static Website', label: 'Simple Static Website' },
          { value: 'Web Development', label: 'Web Development' },
          { value: 'Website Maintenance', label: 'Website Maintenance' },
          { value: 'Wordpress', label: 'Wordpress' },
          { value: 'Woo Commerce', label: 'Woo Commerce' },
          { value: 'Other', label: 'Other' }
      ]
  },
  {
      "Digital Marketing": [
          { value: 'Creative Writing', label: 'Creative Writing' },
          { value: 'E Commerce Marketing', label: 'E Commerce Marketing' },
          { value: 'Email Marketing', label: 'Email Marketing' },
          { value: 'Funnel Building', label: 'Funnel Building' },
          { value: 'Local SEO', label: 'Local SEO' },
          { value: 'Mobile App Marketing', label: 'Mobile App Marketing' },
          { value: 'PPC', label: 'PPC' },
          { value: 'SEO', label: 'SEO' },
          { value: 'SMS Marketing', label: 'SMS Marketing' },
          { value: 'Social Media Advertising', label: 'Social Media Advertising' },
          { value: 'Social Media Management', label: 'Social Media Management' },
          { value: 'Social Media Marketing', label: 'Social Media Marketing' },
          { value: 'Technical Writing', label: 'Technical Writing' },
          { value: 'Video Marketing', label: 'Video Marketing' },
          { value: 'Other', label: 'Other' }
      ]
  },
  {
      "Design Services": [
          { value: 'App Design', label: 'App Design' },
          { value: 'Brand Identity', label: 'Brand Identity' },
          { value: 'Brand Style Guide', label: 'Brand Style Guide' },
          { value: 'Brochure Design', label: 'Brochure Design' },
          { value: 'Catalogue Design', label: 'Catalogue Design' },
          { value: 'Landing Page Design', label: 'Landing Page Design' },
          { value: 'Logo Design', label: 'Logo Design' },
          { value: 'Social Media Creatives', label: 'Social Media Creatives' },
          { value: 'UX Design', label: 'UX Design' },
          { value: 'Other', label: 'Other' }
        ]
  },
  {
      "Marketing": [
          { value: 'Affiliate Marketing', label: 'Affiliate Marketing' },
          { value: 'B2B Marketing', label: 'B2B Marketing' },
          { value: 'Content Marketing', label: 'Content Marketing' },
          { value: 'Inbound Marketing', label: 'Inbound Marketing' },
          { value: 'Influencer Advt', label: 'Influencer Advt' },
          { value: 'Offline Marketing', label: 'Offline Marketing' },
          { value: 'Outbound Marketing', label: 'Outbound Marketing' },
          { value: 'Paid Media', label: 'Paid Media' },
          { value: 'Other', label: 'Other' }
        ]
  },
  {
      "Advertising": [
          { value: 'Meta Ads', label: 'Meta Ads' },
          { value: 'Google Ads', label: 'Google Ads' },
          { value: 'LinkedIn Ads', label: 'LinkedIn Ads' },
          { value: 'FM Radio Advertisement', label: 'FM Radio Advertisement' },
          { value: 'Newspaper Advertisement', label: 'Newspaper Advertisement' },
          { value: 'PPC', label: 'PPC' },
          { value: 'YouTube Advertising', label: 'YouTube Advertising' },
          { value: 'Other', label: 'Other' }
        ]
  }
]